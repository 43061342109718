.user-card {
  margin: 10px 0;
  height: 500px;
  overflow: scroll;
}

.icon-button {
  cursor: pointer;
}

.error-area {
  height: 0;
  max-height: 0;
}

.error-area.opend {
  height: auto;
  max-height: 500px;
  transition: max-height 1s linear;
}
