.loading {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0, 0.2);
  z-index: 1;
}

.spinner {
  display: block;
  color: #fff;
  z-index: 2;
  height: 100%;
}